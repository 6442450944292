<template>
  <div class="abbott-test">
    <div class="wellcome">
      <h4 class="small_text">{{ $t("systemTest.testSystemInfoTitle") }}</h4>
      <form
        v-if="!showTechInfo"
        class="flex flex-column align-center"
        @submit="testRequest($event)"
      >
        <input
          id="test-input-email"
          v-model="testInputEmail"
          class="test-input-email"
          type="email"
          :placeholder="$t('systemTest.yourEmail')"
        />
        <ws-button lg type="submit" color="primary" font-size="xl" class="abbott-form__btn">
          {{ $t("systemTest.systemTest") }}
        </ws-button>
        <div v-show="emailError" class="error">
          {{ $t("systemTest.pleaseEnterYourEmail") }}
        </div>
      </form>
      <p class="cancel-link">
        <router-link to="/login" class>
          {{ $t("common.back") }}
        </router-link>
      </p>
      <div class="user_result">
        <transition name="fade">
          <div
            v-if="clientIsNew && checkConnect && checkPortsResult && dynamicTestComplete"
            id="resultStatusOk"
          >
            <div class="icon_warning success"></div>
            <div class="content">
              <h4>{{ $t("systemTest.broadcastAvailable") }}</h4>
              <p style="color:#ececec;">
                {{ $t("systemTest.testSuccessMessage") }}
              </p>
            </div>
            <div class="clear"></div>
          </div>
        </transition>
        <transition name="fade">
          <div
            v-if="(!clientIsNew || !checkConnect || !checkPortsResult) && dynamicTestComplete"
            id="resultStatusFail"
          >
            <div class="icon_warning error"></div>
            <div class="content">
              <h4>{{ $t("systemTest.warning") }}!</h4>
              <div style="color:#ececec;">
                {{ $t("systemTest.broadcastErrorsPossibleBecause") }}
                <span v-if="!clientIsNew">
                  {{ $t("systemTest.yourBrowserIsOutOfDate") }}
                </span>
                <span v-if="!clientIsNew && !checkConnect">/</span>
                <span v-if="!checkConnect">
                  {{ $t("systemTest.lowSpeed") }}
                </span>
                <span v-if="!checkConnect && !checkPortsResult">/</span>
                <p v-if="!checkPortsResult" style="color:#ececec;">
                  {{ $t("systemTest.requiredPortsNotAvailable") }}
                </p>
                <p v-for="port in failedPorts" v-bind:key="port" style="color:red;">
                  {{ port }}
                </p>
                <p v-if="!checkPortsResult" style="color:#ececec;">
                  {{ $t("systemTest.useAlternativeNetwork") }}
                </p>
              </div>
            </div>
            <div v-if="!clientIsNew" class="install">
              <a href="https://www.google.ru/chrome/browser/desktop/index.html">{{
                $t("systemTest.installChrome")
              }}</a>
            </div>
            <div class="clear"></div>
          </div>
        </transition>
      </div>
    </div>
    <div v-if="showTechInfo" id="test">
      <div class="user_info">
        <ul id="testSystemTable">
          <li id="test-client-browser un-animated" :class="testStatuses.browser.status || ''">
            <div class="status fixed">
              <div class="progress"></div>
            </div>
            <div class="statusText">{{ browser.name }} {{ browser.version }}</div>
            <h3 class="title">{{ $t("systemTest.yourBrowser") }}</h3>
          </li>
          <li id="test-client-speed" class="ok"> <!-- TODO: Костыль скрытия теста, потом обязательно убрать! тут было :class="testStatuses.ports.status || ''" -->
            <div class="status">
              <svg
                width="100%"
                height="100%"
                class="progress"
                version="1.1"
                viewBox="-12 -12 24 24"
              >
                <circle
                  cx="0"
                  cy="0"
                  fill-opacity="0"
                  r="10"
                  stroke="#fff"
                  stroke-opacity="1"
                  stroke-width="3"
                  transform="rotate(-90 0 0)"
                  stroke-dasharray="64"
                  stroke-dashoffset="0"
                ></circle>
              </svg>
            </div>
            <div class="statusText">Проблем не обнаружено</div> <!-- TODO: Костыль скрытия теста, потом обязательно убрать! тут было {{ testStatuses.speed.value }} -->
            <h3 class="title">{{ $t("systemTest.portsAndProtocols") }}</h3>
          </li>
          <li id="test-client-browser1" :class="testStatuses.browser1.status || ''">
            <div class="status">
              <svg
                width="100%"
                height="100%"
                class="progress"
                version="1.1"
                viewBox="-12 -12 24 24"
              >
                <circle
                  cx="0"
                  cy="0"
                  fill-opacity="0"
                  r="10"
                  stroke="#fff"
                  stroke-opacity="1"
                  stroke-width="3"
                  transform="rotate(-90 0 0)"
                  stroke-dasharray="64"
                  stroke-dashoffset="0"
                ></circle>
              </svg>
            </div>
            <!-- testDownload -->
            <div class="statusText">{{ testStatuses.browser1.value }}</div>
            <h3 class="title">{{ $t("systemTest.download") }}</h3>
          </li>
          <li id="test-client-browser2" :class="testStatuses.browser2.status || ''">
            <div class="status">
              <svg
                width="100%"
                height="100%"
                class="progress"
                version="1.1"
                viewBox="-12 -12 24 24"
              >
                <circle
                  cx="0"
                  cy="0"
                  fill-opacity="0"
                  r="10"
                  stroke="#fff"
                  stroke-opacity="1"
                  stroke-width="3"
                  transform="rotate(-90 0 0)"
                  stroke-dasharray="64"
                  stroke-dashoffset="0"
                ></circle>
              </svg>
            </div>
            <!-- testUpload -->
            <div class="statusText">{{ testStatuses.browser2.value }}</div>
            <h3 class="title">{{ $t("systemTest.upload") }}</h3>
          </li>
          <li id="test-client-browser3" :class="testStatuses.browser3.status || ''">
            <div class="status">
              <svg
                width="100%"
                height="100%"
                class="progress"
                version="1.1"
                viewBox="-12 -12 24 24"
              >
                <circle
                  cx="0"
                  cy="0"
                  fill-opacity="0"
                  r="10"
                  stroke="#fff"
                  stroke-opacity="1"
                  stroke-width="3"
                  transform="rotate(-90 0 0)"
                  stroke-dasharray="64"
                  stroke-dashoffset="0"
                ></circle>
              </svg>
            </div>
            <!-- testJitter -->
            <div class="statusText">{{ testStatuses.browser3.value }}</div>
            <h3 class="title">{{ $t("systemTest.jitter") }}</h3>
          </li>
          <li id="test-client-browser5" :class="testStatuses.browser5.status || ''">
            <div class="status">
              <svg
                width="100%"
                height="100%"
                class="progress"
                version="1.1"
                viewBox="-12 -12 24 24"
              >
                <circle
                  cx="0"
                  cy="0"
                  fill-opacity="0"
                  r="10"
                  stroke="#fff"
                  stroke-opacity="1"
                  stroke-width="3"
                  transform="rotate(-90 0 0)"
                  stroke-dasharray="64"
                  stroke-dashoffset="0"
                ></circle>
              </svg>
            </div>
            <!-- testPing -->
            <div class="statusText">{{ testStatuses.browser5.value }}</div>
            <h3 class="title">{{ $t("systemTest.latency") }}</h3>
          </li>
          <li id="test-client-browser4" :class="testStatuses.browser4.status || ''">
            <div class="status">
              <svg
                width="100%"
                height="100%"
                class="progress"
                version="1.1"
                viewBox="-12 -12 24 24"
              >
                <circle
                  cx="0"
                  cy="0"
                  fill-opacity="0"
                  r="10"
                  stroke="#fff"
                  stroke-opacity="1"
                  stroke-width="3"
                  transform="rotate(-90 0 0)"
                  stroke-dasharray="64"
                  stroke-dashoffset="0"
                ></circle>
              </svg>
            </div>
            <!-- testIp -->
            <div class="statusText">{{ testStatuses.browser4.value }}</div>
            <h3 class="title">IP</h3>
          </li>
          <li class="test-browser-info">
            <h2 class="test-browser-info__title">
              {{ $t("systemTest.technicalInformation") }}
            </h2>
            <div class="test-browser-info__row">
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">{{ $t("systemTest.browser") }}:</div>
                <div class="test-browser-info__value">{{ browser.name }} {{ browser.version }}</div>
              </div>
              <div class="test-browser-info__item"></div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">ip</div>
                <div class="test-browser-info__value">
                  {{ freegeoip.ip || $t("systemTest.calculation") + "..." }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">
                  {{ $t("systemTest.city") }}
                </div>
                <div class="test-browser-info__value">
                  {{ freegeoip.city || $t("systemTest.calculation") + "..." }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">
                  {{ $t("systemTest.countryCode") }}
                </div>
                <div class="test-browser-info__value">
                  {{ freegeoip.country_code || $t("systemTest.calculation") + "..." }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">
                  {{ $t("systemTest.countryName") }}
                </div>
                <div class="test-browser-info__value">
                  {{ freegeoip.country_name || $t("systemTest.calculation") + "..." }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">
                  {{ $t("systemTest.regionCode") }}
                </div>
                <div class="test-browser-info__value">
                  {{ freegeoip.region_code || $t("systemTest.calculation") + "..." }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">
                  {{ $t("systemTest.regionName") }}
                </div>
                <div class="test-browser-info__value">
                  {{ freegeoip.region_name || $t("systemTest.calculation") + "..." }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">
                  {{ $t("systemTest.timeZone") }}
                </div>
                <div class="test-browser-info__value">
                  {{ freegeoip.time_zone || $t("systemTest.calculation") + "..." }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">
                  {{ $t("systemTest.zipCode") }}
                </div>
                <div class="test-browser-info__value">
                  {{ freegeoip.zip || $t("systemTest.calculation") + "..." }}
                </div>
              </div>
            </div>
          </li>
          <li class="test-browser-info">
            <h2 class="test-browser-info__title">
              {{ $t("systemTest.browserInformation") }}
            </h2>
            <div class="test-browser-info__row">
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">{{ $t("systemTest.isMobile") }}:</div>
                <div class="test-browser-info__value">
                  {{ deviceInfo.isMobile }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">{{ $t("systemTest.userAgent") }}:</div>
                <div class="test-browser-info__value">
                  {{ navigator.userAgent || $t("systemTest.unknown") }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">{{ $t("systemTest.cookieEnabled") }}:</div>
                <div class="test-browser-info__value">
                  {{ navigator.cookieEnabled || $t("systemTest.unknown") }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">{{ $t("systemTest.vendor") }}:</div>
                <div class="test-browser-info__value">
                  {{ navigator.vendor || $t("systemTest.unknown") }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">{{ $t("systemTest.product") }}:</div>
                <div class="test-browser-info__value">
                  {{ navigator.product || $t("systemTest.unknown") }}
                </div>
              </div>
              <div class="test-browser-info__item">
                <div class="test-browser-info__param">
                  {{ $t("systemTest.localStorageSupport") }}:
                </div>
                <div class="test-browser-info__value">
                  {{ localStorageSupport || $t("systemTest.unknown") }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import bowser from "bowser";
import TestWorker from "worker-loader!@/utils/speedtest.worker";
import { mapState } from "vuex";
import TestUserSystemService from "@/services/TestUserSystemService";
import { room } from "@/store/modules/store.namespaces";
import config from "@/settings/config";
import WsButton from "@/components/base/WsButton";

const INCREASE_PERCENT_INTERVAL = 350;
const { navigator } = window;
const checkPorts = [
  "https://check.whenspeak.ru:4040/",
  "https://check.whenspeak.ru:8862/",
  "https://check.whenspeak.ru:8443/",
  "https://check.whenspeak.ru:4443/",
  "https://check.whenspeak.ru:8080/",
  "https://check.whenspeak.ru:8433/",
  // "https://check1.whenspeak.ru:4040/",
  // "https://check2.whenspeak.ru:4040/",
  // "https://check3.whenspeak.ru:4040/",
  // "https://check4.whenspeak.ru:4040/",
];
const TEST_EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const bowserResult =
  (bowser.getParser(navigator.userAgent) && bowser.getParser(navigator.userAgent).parsedResult) ||
  {};
let checkPortsTry = 0;
let inputTestEmailGlobal = null;

export default {
  name: "UserSystemTest",
  components: {
    WsButton,
  },
  data() {
    return {
      timeOutId: null,
      showTechInfo: false,
      testWorker: null,
      testInputEmail: "",
      testDownload: "",
      testUpload: "",
      testPing: "",
      navigator,
      testIp: "",
      testJitter: "",
      failedPorts: [],
      deviceInfo: {},
      localStorageSupport: false,
      checkPortsResult: false,
      browser: bowserResult.browser,
      clientIsNew: false,
      checkConnect: false,
      mbConnection: null,
      freegeoip: {
        ip: null,
        city: null,
        country_code: null,
        country_name: null,
        region_code: null,
        region_name: null,
        time_zone: null,
        zip: null,
      },
      emailError: false,
      dynamicTestComplete: false,
      testStatuses: {
        browser: {
          status: null,
          value: null,
        },
        ports: {
          status: null,
          value: null,
        },
        speed: {
          status: null,
          value: this.$t("systemTest.await"),
        },
        browser1: {
          status: null,
          value: this.$t("systemTest.await"),
        },
        browser2: {
          status: null,
          value: this.$t("systemTest.await"),
        },
        browser3: {
          status: null,
          value: this.$t("systemTest.await"),
        },
        browser4: {
          status: null,
          value: this.$t("systemTest.await"),
        },
        browser5: {
          status: null,
          value: this.$t("systemTest.await"),
        },
      },
    };
  },
  computed: {
    ...mapState(room, {
      locale: "interfaceLanguage",
    }),
    testPassed() {
      return (
        this.clientIsNew && this.checkConnect && this.checkPortsResult && this.dynamicTestComplete
      );
    },
  },
  watch: {
    testInputEmail(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.emailError = false;
      }
    },
    locale(newLocale, oldLocale) {
      if (newLocale && oldLocale) {
        TestUserSystemService.getFreeGeoIp(newLocale).then(resp => {
          if (resp && resp.data) {
            Object.keys(this.freegeoip).forEach(key => {
              this.freegeoip[key] = resp.data[key] || this.$t("systemTest.unknown");
            });
          }
        });
      }
    },
  },
  created() {
    this.clientIsNew = this.$isSupportBrowser;
    if (this.clientIsNew) {
      this.testStatuses.browser.status = "ok";
    }
    this.checkPortsFunction();
    this.localStorageSupport = !!window.localStorage;
    TestUserSystemService.checkSpeed().then(resp => {
      if (resp && !resp.error) {
        this.mbConnection = resp.mbConnection;
        this.checkConnect = resp.checkConnect;
      }
    });
    TestUserSystemService.getFreeGeoIp(this.locale).then(resp => {
      if (resp && resp.data) {
        Object.keys(this.freegeoip).forEach(key => {
          this.freegeoip[key] = resp.data[key] || this.$t("systemTest.unknown");
        });
      }
    });
    this.deviceInfo = {
      isMobile: this.$isMobile,
    };

    const statusToStatus = status => (status === "Fail" ? 0 : status);

    this.testWorker = new TestWorker();
    this.testWorker.onmessage = ({ data }) => {
      this.testIp = this.$t("systemTest.undefined");
      this.testDownload = statusToStatus("Fail");
      this.testUpload = statusToStatus("Fail");
      this.testPing = statusToStatus("Fail");
      this.testJitter = statusToStatus("Fail");

      if (data) {
        data = JSON.parse(data);
        if (data && data.message && data.message === "finished") {
          this.testIp = data.clientIp || this.$t("systemTest.undefined");
          this.testDownload = statusToStatus(data.dlStatus);
          this.testUpload = statusToStatus(data.ulStatus);
          this.testPing = statusToStatus(data.pingStatus);
          this.testJitter = statusToStatus(data.jitterStatus);

          if (this.tween) {
            this.tween.kill();
          }
          this.displayResults();
        }
      }
    };
  },
  beforeDestroy() {
    if (this.tween) {
      this.tween.kill();
    }
    if (this.testWorker) {
      this.testWorker.terminate();
    }
  },
  methods: {
    checkPortsFunction() {
      const checkResults = [];
      for (let index = 0, arrLength = checkPorts.length; index < arrLength; index++) {
        const item = checkPorts[index];
        const failedPortIndex = this.failedPorts.indexOf(item);
        const wasFailed = failedPortIndex !== -1;
        setTimeout(function() {
          if (checkResults[index] !== "true") {
            checkResults[index] = "false";
          }
        }, 3000);
        TestUserSystemService.testProvidedUrlAccesbiity(item).then(
          response => {
            if (response.data.result === true) {
              checkResults[index] = "true";
              if (wasFailed) {
                this.failedPorts.splice(failedPortIndex, 1);
              }
            } else if (!wasFailed) {
              this.failedPorts.push(item);
            }
          },
          () => {
            if (!wasFailed) {
              this.failedPorts.push(item);
            }
          },
        );
      }
      setTimeout(() => {
        if (checkResults.indexOf("false") === -1) {
          this.checkPortsResult = true;
        }
        {
          checkPortsTry++;
          if (checkPortsTry < 2) {
            this.checkPortsFunction();
          }
        }
      }, 4000);
    },
    makeSendObj() {
      return {
        email: inputTestEmailGlobal,
        upload: this.testUpload,
        download: this.testDownload,
        ports: this.failedPorts.join(", "),
        ping: this.testPing,
        jitter: this.testJitter,
        browser: `${this.browser.name} ${this.browser.version}`,
        ip: this.freegeoip.ip,
        city: this.freegeoip.city,
        country_code: this.freegeoip.country_code,
        country_name: this.freegeoip.country_name,
        region_name: this.freegeoip.region_name,
        time_zone: this.freegeoip.time_zone,
        zip_code: this.freegeoip.zip,
        isMobile: this.deviceInfo.isMobile,
        userAgent: this.navigator.userAgent,
        cookieEnabled: Number(this.navigator.cookieEnabled),
        vendor: this.navigator.vendor,
        product: this.navigator.product,
        localStorageSupport: Number(this.localStorageSupport),
      };
    },
    visible() {
      let countNum = 0;
      const onUpdate = () => {
        const keys = Object.keys(this.testStatuses);
        if (keys && keys.length) {
          const message = this.$t("systemTest.calculation");
          keys.forEach(key => {
            if (key !== "browser") {
              this.testStatuses[key].value = `${message} (${Math.floor(countNum)}%)`;
            }
          });
          countNum += 1;
        }
        if (countNum <= 100) {
          this.timeOutId = setTimeout(onUpdate, INCREASE_PERCENT_INTERVAL);
        } else {
          this.displayResults();
        }
      };
      onUpdate();
    },
    displayResults() {
      const OK = this.$t("systemTest.suitable");
      const FAIL = this.$t("systemTest.problemsDetected");
      const MBS = this.$t("systemTest.MbS");

      clearTimeout(this.timeOutId);
      this.testStatuses = Object.assign({}, this.testStatuses, {
        ports: {
          status: this.checkPortsResult ? "ok" : "fail",
          value: this.checkPortsResult ? `${this.mbConnection} ${MBS}` : FAIL,
        },
        speed: {
          status: this.checkPortsResult ? "ok" : "fail",
          value: this.checkPortsResult ? OK : FAIL,
        },
        browser1: {
          status: this.testDownload && this.testDownload >= 2 ? "ok" : "fail",
          value: this.testDownload ? `${this.testDownload} ${MBS}` : FAIL,
        },
        browser2: {
          status: this.testUpload ? "ok" : "fail",
          value: this.testUpload ? `${this.testUpload} ${MBS}` : FAIL,
        },
        browser3: {
          status: this.testJitter ? "ok" : "fail",
          value: this.testJitter ? this.testJitter : FAIL,
        },
        browser4: {
          status: this.testIp ? "ok" : "fail",
          value: this.testIp ? this.testIp : FAIL,
        },
        browser5: {
          status: this.testPing ? "ok" : "fail",
          value: this.testPing ? `${this.testPing} мс.` : FAIL,
        },
      });

      TestUserSystemService.systemTestPostRequest({
        passed: this.testPassed ? 1 : 0,
        results: this.makeSendObj(),
      });

      this.dynamicTestComplete = true;
    },
    testRequest(e) {
      e.preventDefault();

      const statuses = this.testStatuses;

      this.visible();

      statuses.speed.status = "";
      statuses.browser1.status = "";
      statuses.browser2.status = "";
      statuses.browser3.status = "";
      statuses.browser4.status = "";
      statuses.browser5.status = "";
      this.dynamicTestComplete = false;
      inputTestEmailGlobal = this.testInputEmail;
      if (!inputTestEmailGlobal || !TEST_EMAIL_REGEXP.test(inputTestEmailGlobal)) {
        this.emailError = true;
        return;
      }

      this.emailError = false;

      if (this.testWorker) {
        this.testWorker.postMessage(`start ${config.serverUrl}`);
      }

      this.showTechInfo = true;
    },
  },
};
</script>

<style scoped lang="less">
.cancel-link {
  padding: 5px 0;
  a {
    color: #fff;
  }
}

.abbott-test {
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  background-color: #313131;
  padding: 15px 2em;
}

.wellcome {
  padding-top: 20px;
  text-align: center;
  form {
    position: relative;
    .error {
      position: absolute;
      font-size: 12px;
      bottom: -5px;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.small_text {
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
}

.test-input-email {
  background-color: #fff;
  font-size: 18px;
  height: 30px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 340px;
  border-radius: 3px;
  padding: 5px;
}

.abbott-form__container {
  padding-bottom: 20px;
}

.abbott-form__label {
  display: block;
  margin-bottom: 5px;
  font-weight: 300;
  color: #fff;
}

.abbott-form__input {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 240px;
  display: block;
  font-family: ProximaNova, sans-serif;
  box-sizing: border-box;
  padding: 5px;
  &::placeholder {
    color: #fff;
  }
}

.abbott-form__link {
  height: 28px;
  font-size: 16px;
  line-height: 28px;
  width: 240px;
  display: block;
  color: #fff;
  text-align: center;
}

@media (max-width: 640px) {
  .abbott-container {
    padding-top: 130px;
    padding-bottom: 100px;
    background-size: 100px;
    .abbott-title {
      font-size: 28px;
    }
    .abbott-text {
      padding-top: 24px;
      font-size: 22px;
    }
  }
}

.abbott-form__btn {
  width: 240px;
  min-width: auto;
  margin: 0 auto 10px;
  cursor: pointer;
}

/* user test start */
#test {
  svg {
    vertical-align: baseline;
    display: none;
  }
  .status:not(".fixed") {
    &:after {
      display: none;
    }
  }
  svg {
    display: inline-block;
    &.progress circle {
      animation-name: progress;
      animation-duration: 30s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-direction: reverse;
    }
  }
  .un-animated {
    svg {
      display: none;
    }
  }
  height: auto;
  h3 {
    margin-top: 0;
  }
  .test-title {
    margin: 0;
  }
  .test-description {
    margin: 0 0 89px 0;
  }
  h4 {
    margin-bottom: 89px;
  }
  .user_info {
    width: 100%;
    max-width: 847px;
    margin: 0 auto 41px;
    #testSystemTable {
      padding-left: 0;
      overflow: hidden;
      li {
        padding: 12px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:not(:last-child) {
          border-bottom: 1px solid #979797;
        }
        .status {
          position: relative;
          float: right;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          z-index: 100;
        }
        .statusText {
          float: right;
          font-size: 16px;
          font-weight: 600;
          text-align: right;
          padding: 0 14px;
          line-height: 25px;
          color: #fff;
        }
        &.ok {
          .statusText {
            color: #35ab52;
          }
          .status {
            &:after {
              content: "";
              position: absolute;
              left: 6px;
              top: 2px;
              width: 20px;
              height: 20px;
              background-image: url(~@/assets/images/teva/res-ok.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 95%;
            }
          }
        }
        &.fail {
          .statusText {
            color: #f5a623;
          }
          .status {
            &:after {
              content: "";
              position: absolute;
              left: 6px;
              top: 2px;
              width: 20px;
              height: 20px;
              background-image: url(~@/assets/images/teva/res-alert.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 95%;
            }
          }
        }
        h3.title {
          color: #fff;
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 0;
        }
      }
    }
  }
}

@keyframes progress {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 64;
  }
}

.test-browser-info {
  // TODO: Костыль скрытия теста, потом обязательно убрать!
  display: none !important;

  padding-bottom: 20px;
  max-width: 850px;
  margin: 0 auto;
}

.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter {
  opacity: 0;
}

.test-browser-info__item {
  line-height: 23px;
  color: #fff;
}

.test-browser-info__title {
  color: #fff;
}

.test-browser-info__param {
  font-weight: bold;
  color: #ddd;
  box-sizing: border-box;
  display: inline;
}

.test-browser-info__row {
  margin-top: 12px;
}

.test-browser-info__value {
  color: #fff;
  box-sizing: border-box;
  display: inline;
  padding-left: 10px;
}

.ok,
.fail {
  .status .progress {
    display: none !important;
  }
}
.user_result {
  width: 100%;
  max-width: 847px;
  margin: 0 auto 43px;
  text-align: left;
  #resultStatusOk {
    border: 1px solid #35ab52;
    padding: 21px 20px 16px 21px;
    margin-bottom: 17px;
    h4 {
      color: #35ab52;
    }
  }
  .icon_warning {
    float: left;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    &.success {
      background-image: url("~@/assets/images/teva/res-ok.svg");
    }
    &.error {
      background-image: url("~@/assets/images/teva/res-alert.svg");
    }
  }
  .content {
    padding: 0;
    margin-left: 76px;
    h4 {
      font-size: 16px;
      margin-bottom: 4px;
      text-align: left;
    }
  }
  #resultStatusFail {

    // TODO: Костыль скрытия теста, потом обязательно убрать!
    display: none !important;

    border: 1px solid #f5a623;
    padding: 21px 20px 16px 21px;
    margin-bottom: 17px;
    .install {
      width: 100%;
      max-width: 181px;
      margin: 0 auto;
      border-radius: 28px;
      border: solid 1px #35ab52;
      text-align: center;
      a {
        font-size: 16px;
        color: #35ab52;
        line-height: 29px;
      }
    }
    h4 {
      color: #f5a623;
    }
    p {
      color: #464646;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 22px;
    }
  }
}

.error {
  color: #f00;
}
</style>
